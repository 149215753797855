html,
body {
	margin: 0;
	padding: 0;
	font-size: 16px; }

.nav {
	background: #2c3e50;
	position: relative;
	z-index: 1000;

	.logo {
		i {
			color: white;
			font-size: 1.5rem;
			padding: 10px;
			&:hover {
				color: #e74c3c;
				transition: color 0.3s; } } }


	.main-nav {

		.hamburger-nav::after {
			font-family: fontawesome;
			content: "\f0c9";
			color: white;
			font-size: 1.5rem;
			position: absolute;
			top: 11px;
			right: 20px;
			cursor: pointer; }

		.menu {
			display: none;
			list-style: none;
			position: absolute;
			top: 64%;
			padding-left: 0;
			width: 100%;
			overflow: hidden;
			a {
				display: block;
				background: #e74c3c;
				text-decoration: none;
				text-align: center;
				padding: 20px;
				border-bottom: 0.5px solid rgba(200, 200, 200, 0.2);

				&:hover {
					background: white;
					transition: background 0.3s ease;
					li {
						color: #c0392b; } }

				li {
					color: white;
					text-transform: uppercase;
					font-family: Montserrat, sans-serif;
					font-weight: 300;
					font-size: 0.8rem;
					letter-spacing: 1px; } } } } }
